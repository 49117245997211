import AWS from 'aws-sdk';
import { configuration } from '@/api/aws-exports.js';
import { Auth } from 'aws-amplify';
import { log } from '@/constants.js';
import 'ol/ol.css';

export const state = {
  uploadState: false,
  downloadLink: '',
  map: null,
  overlay: false
};

export const mutations = {
  setUploadState(state, upload_state) {
    state.uploadState = upload_state;
  },
  setLink(state, data) {
    state.downloadLink = data;
  }
};

// ---------------------- GETTERS ---------------------
export const getters = {
  getCredentials: state => id => {
    return state.services.filter(service => service.service_id === id);
  }
};

// ---------------------- SERVICES ---------------------
export const actions = {
  addMsgs({ commit }, data) {
    commit('pushMsgs', data);
  },
  async setCredentials({ dispatch }) {
    try {
      const session = await Auth.currentSession();
      AWS.config.region = configuration.aws_project_region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: configuration.aws_cognito_identity_pool_id,
        Logins: {
          [`cognito-idp.${configuration.aws_project_region}.amazonaws.com/${configuration.Auth.userPoolId}`]: session
            .getIdToken()
            .getJwtToken()
        }
      });
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'setCredentials',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async refreshToken({ dispatch }) {
    try {
      setInterval(async () => {
        const session = await Auth.currentSession();
        const refresh_token = session.getRefreshToken(); // you'll get session from calling cognitoUser.getSession
        const cognitoUser = await Auth.currentAuthenticatedUser();
        cognitoUser.refreshSession(refresh_token, (err, session) => {
          if (err) {
            dispatch(
              'storeToastMessage',
              {
                text: 'refreshToken cognitoUser.refreshSession',
                errObj: err,
                type: 'danger'
              },
              { root: true }
            );
          } else {
            AWS.config.credentials.params.Logins[
              `cognito-idp.${configuration.aws_project_region}.amazonaws.com/${configuration.Auth.userPoolId}`
            ] = session.getIdToken().getJwtToken();
            AWS.config.credentials.refresh(err => {
              if (err) {
                dispatch(
                  'storeToastMessage',
                  {
                    text: 'refreshToken AWS.config.credentials',
                    errObj: err,
                    type: 'danger'
                  },
                  { root: true }
                );
              } else {
                log.info('TOKEN SUCCESSFULLY UPDATED');
              }
            });
          }
        });
      }, 3000000);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'refreshToken',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async setUpload({ dispatch, commit }, data) {
    const params = data;
    const upload = new AWS.S3.ManagedUpload({
      params,
      leavePartsOnError: true
    });

    try {
      upload.send(function(err, uploaded) {
        if (err) {
          dispatch(
            'storeToastMessage',
            {
              text: 'setUpload send err',
              errObj: err,
              type: 'danger'
            },
            { root: true }
          );
        }

        if (uploaded.Bucket.includes('-')) {
          dispatch(
            'storeToastMessage',
            {
              text: 'upload started',
              type: 'success'
            },
            { root: true }
          );
        }
      });
      commit('setUploadState', true);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'setUpload catch err',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
      commit('setUploadState', false);
    }
  },
  async downloadItem({ dispatch, commit }, data) {
    const s3_bucket = new AWS.S3();
    const response = await s3_bucket.getSignedUrlPromise('getObject', data);

    if (response) {
      commit('setLink', response);
      dispatch(
        'storeToastMessage',
        {
          text: 'Download link generated',
          type: 'success'
        },
        { root: true }
      );
    } else {
      dispatch(
        'storeToastMessage',
        {
          text: 'Download Link generation failed',
          type: 'error'
        },
        { root: true }
      );
    }
  },
  async deleteS3ItemAction({ dispatch }, data) {
    try {
      const params = data;
      const s3 = new AWS.S3();
      s3.deleteObject(params, function(err, data) {
        if (err) {
          log.error('S3 ERR', err, err.stack);
        } else {
          log.info('S3 DATA', data);
        }
      });
      dispatch(
        'storeToastMessage',
        {
          text: 'Document S3 deleted from bucket',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteS3ItemAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getItem({ dispatch, commit }, data) {
    const s3_bucket = new AWS.S3();
    const response = await s3_bucket.getSignedUrlPromise('getObject', data);

    if (response) {
      commit('setLink', response);
    } else {
      dispatch(
        'storeToastMessage',
        {
          text: 'Error Link failed',
          type: 'error'
        },
        { root: true }
      );
    }
  }
};
