import { getAdmin, newAdmin, deleteAdmin } from '@/api/api.js';
import { configuration } from '@/api/aws-exports';
import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { log } from '@/constants.js';

export const state = {
  headers: [
    {
      text: 'Username',
      align: 'start',
      sortable: true,
      value: 'username'
    },
    { text: 'Name', value: '_name' },
    { text: 'Surname', value: 'surname' },
    { text: 'Email', value: 'email' },
    { text: 'Actions', value: 'actions', sortable: false, width: 130 }
  ],
  admins: [],
  editAdmin: false
};

export const mutations = {
  EDIT_ADMIN_CHANGED(state, edit) {
    state.editAdmin = edit;
  },
  GET_ADMIN(state, AdminArray) {
    state.admins = AdminArray;
  },
  NEW_ADMIN(state, adminToAdd) {
    state.admins.push(adminToAdd.data.createAdminUser);
  },
  DELETE_ADMIN(state, delete_username) {
    state.admins.forEach(element => {
      if (element.username == delete_username) {
        const index = state.admins.indexOf(element);
        state.admins.splice(index, 1);
      }
    });
  }
};

export const actions = {
  changeEditAdmin({ commit }, edit) {
    commit('EDIT_ADMIN_CHANGED', edit);
  },

  async storeAdmins({ dispatch, commit }) {
    try {
      const admin = await getAdmin();
      commit('GET_ADMIN', admin.data.listAdmin);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeAdmins',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeNewAdmin({ commit, dispatch }, admin) {
    try {
      const adminToAdd = await newAdmin(
        admin.username,
        admin._name,
        admin.surname,
        admin.email
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'admin created',
          type: 'success'
        },
        { root: true }
      );
      commit('NEW_ADMIN', adminToAdd);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeNewAdmin',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async deleteTableAdmin({ dispatch, commit }, username) {
    try {
      dispatch(
        'storeToastMessage',
        {
          text: 'start to delete admin',
          type: 'info'
        },
        { root: true }
      );
      const adminToDelete = await deleteAdmin(username);
      dispatch(
        'storeToastMessage',
        {
          text: 'admin deleted',
          type: 'success'
        },
        { root: true }
      );
      commit('DELETE_ADMIN', adminToDelete.data.deleteAdminUser.username);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteTableAdmin',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async setCredentials({ dispatch }) {
    try {
      const session = await Auth.currentSession();
      AWS.config.region = configuration.Auth.region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: configuration.cognito_identity_pool_id,
        Logins: {
          [`cognito-idp.${configuration.Auth.region}.amazonaws.com/${configuration.Auth.userPoolId}`]: session
            .getIdToken()
            .getJwtToken()
        }
      });
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'setCredentials',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async refreshToken({ dispatch }) {
    try {
      setInterval(async () => {
        const session = await Auth.currentSession();
        const refresh_token = session.getRefreshToken(); // you'll get session from calling cognitoUser.getSession
        const cognitoUser = await Auth.currentAuthenticatedUser();
        cognitoUser.refreshSession(refresh_token, (err, session) => {
          if (err) {
            dispatch(
              'storeToastMessage',
              {
                text: 'refreshToken cognitoUser.refreshSession',
                errObj: err,
                type: 'danger'
              },
              { root: true }
            );
          } else {
            AWS.config.credentials.params.Logins[
              `cognito-idp.${configuration.Auth.region}.amazonaws.com/${configuration.Auth.userPoolId}`
            ] = session.getIdToken().getJwtToken();
            AWS.config.credentials.refresh(err => {
              if (err) {
                dispatch(
                  'storeToastMessage',
                  {
                    text: 'refreshToken AWS.config.credentials',
                    errObj: err,
                    type: 'danger'
                  },
                  { root: true }
                );
              } else {
                log.info('TOKEN SUCCESSFULLY UPDATED');
              }
            });
          }
        });
      }, 3000000);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createWorkspaceServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
