<template>
  <v-card>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card-title v-if="!isEditing">
      Create Application
    </v-card-title>
    <v-card-title v-else-if="disableForm">
      Application
    </v-card-title>
    <v-card-title
      v-else-if="isTranslating"
    >
      Application Translate {{ selectedLanguage }}
    </v-card-title>
    <v-card-title v-else>
      Edit Application
    </v-card-title>
    <v-card-text>
      <v-form
        ref="serviceForm"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="serviceForm.service_name"
          label="Application Name"
          required
          :rules="service_name_rules"
          :counter="255"
          :disabled="isEditing && disableForm"
        />
        <v-textarea
          v-model="serviceForm.description"
          auto-grow
          class="mt-2"
          label="Application description"
          :rules="description_rules"
          :counter="512"
          :disabled="isEditing && disableForm"
        />
        <v-radio-group
          v-if="!isEditing"
          v-model="serviceForm.service_type"
          required
          label="Application type"
        >
          <v-radio
            label="Workspace"
            value="WS"
          />
          <v-radio
            label="Url"
            value="URL"
          />
          <v-radio
            label="Documental"
            value="DOC"
          />
          <v-radio
            label="B2B"
            value="B2B"
          />
          <v-radio
            label="Ticketing"
            value="TICKET"
          />
          <v-radio
            label="Alert Ex-Post"
            value="ALERT EX-POST"
          />
          <v-radio
            label="Reports"
            value="REPORTS"
          />
          <v-radio
            label="Report Hub"
            value="REPORT-HUB"
          />
        </v-radio-group>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="!isTranslating"
        light
        color="color_green"
        style="color:white"
        class="ml-4"
        :disabled="checkForm()"
        @click="submit"
      >
        <span v-if="!isEditing">Create Application</span>
        <span v-else>Edit Application</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required, manageLenghtError } from '@/formRules.js';
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    serviceForm: {
      type: Object,
      default: null
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isTranslating: {
      type: Boolean,
      default: false
    },
    disableForm: {
      type: Boolean,
      default: false
    },
    selectedLanguage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      service_name_rules: [...required, ...manageLenghtError(255)],
      service_types_rules: [...required],
      description_rules: [...manageLenghtError(512)],
      valid: true,
      overlay: false
    };
  },
  computed: {
    ...mapState(['services'])
  },
  methods: {
    ...mapActions([
      'createServiceAction',
      'storeServices',
      'editServiceAction'
    ]),
    checkForm() {
      return !(this.serviceForm.service_name !== '' &&
        this.serviceForm.service_type !== '');
    },
    async submit() {
      if (!this.isEditing) {
        this.overlay = true;
        await this.createServiceAction(this.serviceForm);
        this.services.showCreateService = false;
        this.overlay = false;
        this.storeServices();
      } else {
        Object.assign(this.serviceForm, {
          service_old_name: this.services.storeServiceForm.service_name
        });
        delete this.serviceForm.service_state;
        this.overlay = true;
        await this.editServiceAction(this.serviceForm);
        this.services.showCreateService = false;
        this.overlay = false;
        this.storeServices();
      }
    }
  }
};
</script>
