import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        color_green: '#0ca344'
      },
      light: {
        color_green: '#0ca344'
      }
    }
  }
});
