import { STAGE } from '@/constants';

const AWS_REGION = 'eu-central-1';

const CognitoIdentityPoolId = {
  dev: 'eu-central-1:b3f59751-ad93-4527-8098-d1c09b6db2c1',
  ppr: 'eu-central-1:d4c32452-ee7a-41b6-b120-ee4910767ff4',
  prod: 'eu-central-1:0786fce0-75a7-4e82-be0b-6d5cd605a6e8'
};

const CognitoPoolId = {
  dev: 'eu-central-1_2cEVVanPR',
  ppr: 'eu-central-1_CM9VZtvgJ',
  prod: 'eu-central-1_9o9x2cxKP'
};

const CognitoClientId = {
  dev: '58466auob95i2epov6per0sp1v',
  ppr: '45hlrfeb9915ohd6t6c7908h4d',
  prod: 't2kv597hgt4j5ai1jb5m42fo4'
};

const graphQlEnpoints = {
  dev:
    'https://qmn6zlnijfesfajiu2spzbozzi.appsync-api.eu-central-1.amazonaws.com/graphql',
  ppr:
    'https://ljjjpvlsbvefvog3jc6cw5nihu.appsync-api.eu-central-1.amazonaws.com/graphql',
  prod:
    'https://7tomnkux75axlkc3ns2ixqgzo4.appsync-api.eu-central-1.amazonaws.com/graphql'
};

const getValueByEnvironment = (valueSet, env) => valueSet[env];

export const configuration = {
  Auth: {
    region: AWS_REGION,
    userPoolId: getValueByEnvironment(CognitoPoolId, STAGE),
    userPoolWebClientId: getValueByEnvironment(CognitoClientId, STAGE)
  },
  aws_project_region: AWS_REGION,
  aws_appsync_graphqlEndpoint: getValueByEnvironment(graphQlEnpoints, STAGE),
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_identity_pool_id: getValueByEnvironment(
    CognitoIdentityPoolId,
    STAGE
  )
};
