export const getAdmin = /* GraphQL */ `
  query getAdmin {
    listAdmin {
      username
      _name
      surname
      email
    }
  }
`;

export const getCustomers = /* GraphQL */ `
  query getCustomers {
    listCustomers {
      crm_id
      ragione_sociale
      c_description
      latitude
      longitude
      zoom
      map_style
      logo
      state
    }
  }
`;

export const getTechUsersByCustomer = /* GraphQL */ `
  query getTechUsersByCustomer($crm_id: String!) {
    listTechUsersByCustomer(crm_id: $crm_id) {
      username
      email
      _state
      policy_id
    }
  }
`;

export const getPoliciesTU = /* GraphQL */ `
  query getPolicies {
    listPolicies {
      policy_id
      policy_name
    }
  }
`;

export const getAppUsersByCustomer = /* GraphQL */ `
  query getAppUsersByCustomer($crm_id: String!) {
    listAppUsersByCustomer(crm_id: $crm_id) {
      app_user_id
      username
      _name
      surname
      email
      _state
      phone
      language
      cognito_user_created
      user_map_style
    }
  }
`;

export const getPolicies = /* GraphQL */ `
  query getPolicies {
    listPolicies {
      policy_id
      policy_name
      policy_description
    }
  }
`;

export const getWorkspacesByPolicy = /* GraphQL */ `
  query getWorkspacesByPolicy($policy_id: Int!) {
    listWorkspacesByPolicy(policy_id: $policy_id) {
      workspace_name
      n_calls
      start_time
      stop_time
      date_type
    }
  }
`;

export const getDBLayers = /* GraphQL */ `
  query getDBLayers($workspace_name: String!, $policy_id: Int!) {
    listDBLayers(workspace_name: $workspace_name, policy_id: $policy_id) {
      workspace_name
      policy_id
      layer_name
    }
  }
`;

export const getDomainsWS = /* GraphQL */ `
  query getDomainsWS {
    listDomains {
      domain_id
      domain_name
    }
  }
`;

export const getDomainsByWS = /* GraphQL */ `
  query getDomainsByWS($workspace_name: String!, $policy_id: Int!) {
    listDomainsByWorkspace(
      workspace_name: $workspace_name
      policy_id: $policy_id
    ) {
      domain_id
      domain_name
    }
  }
`;

export const getDomains = /* GraphQL */ `
  query getDomains {
    listDomains {
      domain_id
      domain_name
      domain_description
      polygon
    }
  }
`;

export const checkUserDomain = /* GraphQL */ `
  query checkUserDomain($domain_id: Int!) {
    checkUserDomain(domain_id: $domain_id) {
      result
    }
  }
`;

export const checkUserPolicy = /* GraphQL */ `
  query checkUserPolicy($policy_id: Int!) {
    checkUserPolicy(policy_id: $policy_id) {
      result
    }
  }
`;

export const getServices = /* GraphQL */ `
  query getServices {
    listServices {
      description
      service_id
      service_name
      service_state
      service_type
      manual_s3_path
    }
  }
`;

export const getTranslationServices = /* GraphQL */ `
  query getTranslationServices($input: ListTranslationServiceInput!) {
    listTranslationServices(input: $input) {
      description
      service_name
    }
  }
`;

export const getTranslation = /* GraphQL */ `
  query getTranslation($input: GetTranslationInput!) {
    getTranslation(input: $input) {
      label_code
      label_value
      lang
    }
  }
`;

export const getUrlServiceConfiguration = /* GraphQL */ `
  query getUrlServiceConfiguration($service_id: String!) {
    getUrlServiceConfiguration(service_id: $service_id) {
      external_link
      father_id
      node_id
      node_name
      service_id
      sort_id
      node_description
    }
  }
`;

export const getDocumentalServiceConfiguration = /* GraphQL */ `
  query getDocumentalServiceConfiguration($service_id: String!) {
    getDocumentalServiceConfiguration(service_id: $service_id) {
      path_s3_bucket
      father_id
      node_id
      node_name
      node_description
      service_id
      sort_id
    }
  }
`;

export const getReportHubServiceConfiguration = /* GraphQL */ `
  query getReportHubServiceConfiguration($service_id: String!) {
    getReportHubServiceConfiguration(service_id: $service_id) {
      path_s3_bucket
      father_id
      node_id
      node_name
      node_description
      service_id
      sort_id
      crm_folder
    }
  }
`;

export const getWorkspaceConfiguration = /* GraphQL */ `
  query getWorkspaceConfiguration($input: WsConfigServiceInput!) {
    getWorkspaceConfiguration(input: $input) {
      service_id
      ws_id
      ws_name
      ws_group
      is_graph
      is_animation
      is_table
      is_meteogram
      is_map
      layer_name
      layer_color
      layer_label
      udm
      animation_limit_date_type
      animation_limit_future
      animation_limit_past
      animation_step_date_type
      animation_step
      archive_start_date
      refresh_interval_step_type
      refresh_interval_step
      globalLayers {
        global_layer_id
        layer_name
        layer_description
        layer_polygon
      }
      info {
        lang
        text
      }
    }
  }
`;

export const getGlobalLayer = /* GraphQL */ `
  query getGlobalLayer($service_id: [String]) {
    getGlobalLayer(service_id: $service_id) {
      global_layer_id
      layer_name
      layer_description
      layer_polygon
      color
      width
      layer_type
    }
  }
`;

export const listGlobalLayerTranslaction = /* GraphQL */ `
  query listGlobalLayerTranslaction($input: ListGlobalLayerTranslactionInput!) {
    listGlobalLayerTranslaction(input: $input) {
      global_layer_id
      layer_name
      layer_description
    }
  }
`;

export const getTranslationNodes = /* GraphQL */ `
  query getTranslationNodes($input: [ListTranslationNodesInput]!) {
    listTranslationNodes(input: $input) {
      node_id
      node_name
      node_description
    }
  }
`;

export const getServicesPolicy = /* GraphQL */ `
  query getServicesPolicy {
    listServicesPolicy {
      service_id
      policy_id
      policy_name
      policy_description
      policy_state
      pws_id
    }
  }
`;

export const listLinkedAppUserPolicy = /* GraphQL */ `
  query listLinkedAppUserPolicy($input: [DeleteAppUserInput]!) {
    listLinkedAppUserPolicy(input: $input) {
      service_id
      service_type
      service_name
      description
      manual_s3_path
      service_state
      policy_id
      app_user_id
      policy_name
      policy_description
      policy_state
    }
  }
`;

export const listConfigurationPolicy = /* GraphQL */ `
  query listConfigurationPolicy($input: ListConfigurationPolicyInput!) {
    listConfigurationPolicy(input: $input) {
      URL {
        service_id
        policy_id
        node_id
        external_link
        node_name
      }
      DOC {
        service_id
        policy_id
        node_id
        path_s3_bucket
        node_name
      }
    }
  }
`;

export const listLinkedServices = /* GraphQL */ `
  query listLinkedServices($crm_id: String!) {
    listLinkedServices(crm_id: $crm_id) {
      service_id
      crm_id
      service_name
      description
      service_type
      manual_s3_path
      service_state
    }
  }
`;

export const listLinkedCustomers = /* GraphQL */ `
  query listLinkedCustomers($input: listLinkedCustomersInput!) {
    listLinkedCustomers(input: $input) {
      crm_id
      ragione_sociale
      limit_reached
    }
  }
`;

export const listTicketingRequestLimit = /* GraphQL */ `
  query listTicketingRequestLimit($crm_id: [String]!) {
    listTicketingRequestLimit(crm_id: $crm_id) {
      crm_id
      request_limit
      request_count
      limit_reached
    }
  }
`;

export const getTicketingServiceConfiguration = /* GraphQL */ `
  query getTicketingServiceConfiguration($service_id: String!) {
    getTicketingServiceConfiguration(service_id: $service_id) {
      service_id
      ticket_id
      date_limit
      config {
        document_id
        document_type 
        meteo_types {
          meteo_id
          name
	        is_auto
	        is_manual
        }
      }
    }
  }
`;

export const getTicketingServiceTranslation = /* GraphQL */ `
  query getTicketingServiceTranslation($input: GetTicketingServiceTranslationInput!) {
    getTicketingServiceTranslation(input: $input) {
      label_code
      label_value
      lang
    }
  }
`;

export const getProfilingTicketingService = /* GraphQL */ `
  query getProfilingTicketingService($service_id: String!) {
    getProfilingTicketingService(service_id: $service_id) {
      hierarchy {
        father_id
        node_description
        node_id
        node_name
        service_id
        sort_id
      }
      profiling {
        node_id
        permission_id
        permission_name
        permission_type
      }
    }
  }
`;

export const getLinkedAppUserToProfilingNode = /* GraphQL */ `
  query getLinkedAppUserToProfilingNode($node_id: String!) {
    getLinkedAppUserToProfilingNode(node_id: $node_id) {
      app_user_id
      customer_id
      _name
      surname
      email
      group_id
      username
      service_id
      policy_id
    }
  }
`;

export const listEvasionLimitSlots = /* GraphQL */ `
  query listEvasionLimitSlots($dates_to_check: [String]!) {
    listEvasionLimitSlots(dates_to_check: $dates_to_check) {
      evasion_limit
      max_slots
      total_slots
      is_prov
      can_be_moved
    }
  }
`;

export const listComuniProvince = /* GraphQL */ `
  query listComuniProvince($is_prov: Boolean!) {
    listComuniProvince(is_prov: $is_prov) {
      list
    }
  }
`;

export const listDocumentMeteoTypes = /* GraphQL */ `
  query listDocumentMeteoTypes($type: String!) {
    listDocumentMeteoTypes(type: $type) {
      list
    }
  }
`;

export const listFilteredTicketingRequest = /* GraphQL */ `
  query listFilteredTicketingRequest($input: ListFilteredTicketingRequestInput!) {
    listFilteredTicketingRequest(input: $input) {
      service_id
      request_id
      request_type
      app_user_id
      app_user_ticket_id
      document_type
      username
      evasion_date
      evasion_limit
      request_date
      request_state
      notes
      additional_notes
      locations {
        request_id
        location_id
        latitude
        longitude
        meteo_type
        address
        start_date
        end_date
      }
      request_number
      request_count
      full_address
      surname
      email
      phone
      crm_id
      ragione_sociale
      node_name
      send_email
      _name
      full_name
    }
  }
`;

export const listProfilingAppUser = /* GraphQL */ `
  query listProfilingAppUser($crm_id: String!) {
    listProfilingAppUser(crm_id: $crm_id) {
      app_user_id
      _name
      surname
      email
      username
      is_already_linked
    }
  }
`;

export const getTicketingRequestComplete = /* GraphQL */ `
  query getTicketingRequestComplete($input: GetTicketingRequestCompleteInput!) {
    getTicketingRequestComplete(input: $input) {
      service_id
      request_id
      request_type
      app_user_id
      app_user_ticket_id
      document_type
      username
      evasion_date
      evasion_limit
      request_date
      request_state
      notes
      additional_notes
      locations {
        request_id
        location_id
        latitude
        longitude
        meteo_type
        address
        start_date
        end_date
      }
      request_number
      request_count
      full_address
      surname
      email
      phone
      crm_id
      ragione_sociale
      node_name
      send_email
      _name
      full_name
    }
  }
`;

export const getLinkedDocumentS3toTicketingRequest = /* GraphQL */ `
  query getLinkedDocumentS3toTicketingRequest($request_id: String!) {
    getLinkedDocumentS3toTicketingRequest(request_id: $request_id) {
      app_user_ticket_id
      request_id
      request_type
      document_s3_id
      file_name
      document_creator
      document_s3_path
    }
  }
`;

export const listEnabledServicesPolicy = /* GraphQL */ `
  query listEnabledServicesPolicy($input: listEnabledServicesPolicyInput!) {
    listEnabledServicesPolicy(input: $input) {
      DOC {
        app_user_id
        manual_s3_path
        description
        policy_id
        service_id
        service_name
        service_state
        service_type
      }
      URL {
        app_user_id
        manual_s3_path
        description
        policy_id
        service_name
        service_state
        service_type
        service_id
      }
      WS {
        app_user_id
        manual_s3_path
        description
        policy_id
        service_name
        service_state
        service_type
        service_id
      }
      TICKET {
        enabled_service {
          app_user_id
          app_user_ticket_id
          manual_s3_path
          description
          policy_id
          service_name
          service_state
          service_type
          service_id
        }
        permissions {
          service_id
          node_id
          father_id
          node_name
          node_description
          sort_id
          permission_id
          permission_type
          permission_name
          surname
          email
          phone
          crm_id
          ragione_sociale
          _name
        }
      }
      REPORT_HUB {
        app_user_id
        manual_s3_path
        description
        policy_id
        service_id
        service_name
        service_state
        service_type
        crm_folder
      }
    }
  }
`;

export const getConfigurationWorkspacePolicy = /* GraphQL */ `
  query getConfigurationWorkspacePolicy($input: GetConfigurationWorkspacePolicyInput!) {
    getConfigurationWorkspacePolicy(input: $input) {
      service_id
      policy_id
      pws_id
      is_graph
      is_animation
      is_table
      is_meteogram
      is_map
      start_date
      end_date
      date_type
      layers {
        pws_id
        layer_name
        ws_id
        layer_style
      }
      domains {
        pws_id
        domain_id
        domain_name
      }
      globalLayers {
        pws_id
        layer_name
        global_layer_id
      }
      ws_name {
        pws_id
        ws_id
        ws_name
        ws_group
      }
      meteograms {
        meteogram_id
        location_name
        latitude
        longitude
      }
      info {
        lang
        text
      }
    }
  }
`;

export const getCustomerLayer = /* GraphQL */ `
  query getCustomerLayer($crm_id: String!) {
    getCustomerLayer(crm_id: $crm_id) {
      crm_id
      global_layer_id
      layer_name
      layer_description
      layer_polygon
      color
      width
      layer_type
    }
  }
`;

export const listCustomerManuals = /* GraphQL */ `
  query listServiceManuals($customer_id: String!) {
    listCustomerManuals(customer_id: $customer_id) {
      manual_s3_path
      label_code
      label_value
      customer_id
    }
  }
`;

export const listCustomerCommonServices = /* GraphQL */ `
  query listCustomerCommonServices($customer_id: String!) {
    listCustomerCommonServices(customer_id: $customer_id) {
      service_type
      service_id
      service_name
      description
      policy_id
      policy_name
      policy_description
      pws_id
      ws_group
      layer_type
      global_layers {
        global_layer_id
        layer_name
        layer_description
        layer_polygon
        color
        width
        layer_type
      }
    }
  }
`;

export const getDefaultService = /* GraphQL */ `
  query getDefaultService($input: GetDefaultServiceInput!) {
    getDefaultService(input: $input) {
      GLOBAL {
        default_id
        service_type
        app_scope
        service_path
        drawer_title
        global_layer_id {
          id
          name
        }
        customer_layer_id {
          id
          name
        }
        service_details {
          service_id
          layer_name
          layer_type
          is_meteogram
          service_name
        }
      }
      Archive {
        default_id
        service_type
        app_scope
        service_path
        drawer_title
        global_layer_id {
          id
          name
        }
        customer_layer_id {
          id
          name
        }
        service_details {
          service_id
          layer_name
          layer_type
          is_meteogram
          service_name
        }
      }
      RealTime {
        default_id
        service_type
        app_scope
        service_path
        drawer_title
        global_layer_id {
          id
          name
        }
        customer_layer_id {
          id
          name
        }
        service_details {
          service_id
          layer_name
          layer_type
          is_meteogram
          service_name
        }
      }
      Forecast {
        default_id
        service_type
        app_scope
        service_path
        drawer_title
        global_layer_id {
          id
          name
        }
        customer_layer_id {
          id
          name
        }
        service_details {
          service_id
          layer_name
          layer_type
          is_meteogram
          service_name
        }
      }
    }
  }
`;
