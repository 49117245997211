<template>
  <v-container>
    <v-row>
      <v-col>
        <domain-table
          @newDomainEvent="createDomainEvent"
          @pencilEvent="pencilButtonEvent($event)"
          @closeDialogEvent="closeDefineDomain"
        />
      </v-col>
    </v-row>
    <div v-if="showCreateDomain == true">
      <v-row>
        <v-col>
          <territorial-definition
            :key="componentKey"
            :form="item_form"
            @closeDomainDettail="closeDefineDomain"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    DomainTable: () => import('@/components/domain/DomainTable'),
    TerritorialDefinition: () =>
      import('@/components/domain/TerritorialDefinition')
  },
  data() {
    return {
      showCreateDomain: false,
      item_form: {
        domain_id: 0,
        domain_name: '',
        domain_description: ''
      },
      componentKey: 0
    };
  },
  computed: {
    ...mapState(['domain']),
    target() {
      return 9999;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      };
    }
  },

  methods: {
    ...mapActions(['storeModifyPolygon']),

    closeDefineDomain() {
      this.showCreateDomain = false;
    },

    createDomainEvent() {
      if (this.componentKey != 0) {
        this.forceRerender();
      }

      this.item_form = {
        domain_id: 0,
        domain_name: '',
        domain_description: ''
      };
      this.showCreateDomain = true;
      this.domain.isModifingDomain = false;
      this.domain.polyString = '';
      this.$vuetify.goTo(this.target, this.options);
    },

    pencilButtonEvent(item) {
      this.forceRerender();

      this.storeModifyPolygon(item.domain_id);

      this.item_form = JSON.parse(JSON.stringify(item));
      this.showCreateDomain = true;
      this.domain.isModifingDomain = true;
      this.$vuetify.goTo(this.target, this.options);
    },

    forceRerender() {
      this.componentKey += 1;
    }
  }
};
</script>

<style lang="scss" scoped></style>
