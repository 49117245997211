export const log = require('log-util');
const BASE_URLS = {
  dev: 'https://adx9n3xgpl.execute-api.eu-central-1.amazonaws.com/geoserver',
  ppr: 'https://67bsqefyb0.execute-api.eu-central-1.amazonaws.com/geoserver',
  prod: 'https://nzcwqv63j4.execute-api.eu-central-1.amazonaws.com/geoserver'
};

export const getErrorMessage = err => {
  log.info(err);

  if (err) {
    if (err.message) {
      if (err.message.includes('length')) {
        return 'This element is empty';
      } else {
        if (
          err.message != null &&
          err.message != '' &&
          err.message != undefined
        ) {
          return err.message;
        }
      }
    } else {
      if (err.errors && err.errors[0] && err.errors[0].message) {
        if (err.errors[0].message.includes('RDS')) {
          const tmp = err.errors[0].message.split(':');
          tmp.shift();
          const error_message = tmp.join(':');

          return JSON.parse(error_message).message;
        } else {
          return err.errors[0].message;
        }
      }
    }
  }
};

export const STAGE = process.env.VUE_APP_STAGE || 'dev';

export const S3_BUCKETS = {
  application: () => (STAGE === 'prod') ? 'meteocast-application-prd' : `meteocast-application-${STAGE}`,
  global_layer: () => (STAGE === 'prod') ? 'meteocast-global-layer-prd' : `meteocast-global-layer-${STAGE}`,
  customer: () => (STAGE === 'prod') ? 'meteocast-customer-prd' : `meteocast-customer-${STAGE}`
};

export const BASE_URL = BASE_URLS[STAGE];

export const LANGUAGES = [
  { text: 'English', value: 'en-English' },
  { text: 'Spanish', value: 'es-Spanish' },
  { text: 'Deutsch', value: 'de-Deutsch' },
  { text: 'French', value: 'fr-French' }
];

export const DEFAULT_METEO_TYPES = [
  { name: 'Gelo e Brina', is_auto: false, is_manual: true },
  { name: 'Eccesso di pioggia', is_auto: false, is_manual: true },
  { name: 'Vento Caldo', is_auto: false, is_manual: true },
  { name: 'Colpo di Sole', is_auto: false, is_manual: true },
  // { name: 'Siccità', is_auto: false, is_manual: true },
  { name: 'Siccità (PGRA2023)', is_auto: false, is_manual: true },
  { name: 'Siccità (SPEI3)', is_auto: false, is_manual: true },
  { name: 'Sbalzo Termico', is_auto: false, is_manual: true },
  { name: 'Ondata di Calore', is_auto: false, is_manual: true },
  { name: 'Eccesso di neve', is_auto: false, is_manual: true },
  { name: 'Vento forte (soglia a 14 m/s)', is_auto: false, is_manual: true },
  { name: 'Vento forte (soglia a 20 m/s)', is_auto: false, is_manual: true },
  { name: 'Grandine', is_auto: false, is_manual: true },
  { name: 'Fulminazione', is_auto: false, is_manual: true }
];

export const PUNTUALE_METEO_TYPES = [
  { name: 'Gelo e Brina', is_auto: true, is_manual: true },
  { name: 'Eccesso di pioggia', is_auto: false, is_manual: true },
  { name: 'Vento Caldo', is_auto: true, is_manual: true },
  { name: 'Colpo di Sole', is_auto: true, is_manual: true },
  // { name: 'Siccità', is_auto: true, is_manual: true },
  { name: 'Siccità (PGRA2023)', is_auto: true, is_manual: true },
  { name: 'Siccità (SPEI3)', is_auto: true, is_manual: true },
  { name: 'Sbalzo Termico', is_auto: true, is_manual: true },
  { name: 'Ondata di Calore', is_auto: true, is_manual: true },
  { name: 'Eccesso di neve', is_auto: false, is_manual: true },
  { name: 'Vento forte (soglia a 14 m/s)', is_auto: true, is_manual: true },
  { name: 'Vento forte (soglia a 20 m/s)', is_auto: true, is_manual: true },
  { name: 'Grandine', is_auto: true, is_manual: true },
  { name: 'Fulminazione', is_auto: false, is_manual: true }
];

export const SERVICE_TYPES = [
  { text: 'Workspace', value: 'WS' },
  { text: 'Url', value: 'URL' },
  { text: 'Documental', value: 'DOC' },
  { text: 'Ticketing', value: 'TICKET' },
  { text: 'Alert Ex-Post', value: 'ALERT EX-POST' },
  { text: 'Reports', value: 'REPORTS' },
  { text: 'Report Hub', value: 'REPORT-HUB' }
];

export const requestTypes = {
  documentTypes: [
    { document_type: 'Relazione meteorologica' },
    { document_type: 'Report meteorologico comunale' },
    { document_type: 'Report meteorologico puntuale' },
    { document_type: 'Report meteorologico provinciale' }
  ],
  meteoTypes: [
    { meteo_type: 'Colpo di sole' },
    { meteo_type: 'Eccesso di pioggia' },
    { meteo_type: 'Eccesso di neve' },
    { meteo_type: 'Fulminazione' },
    { meteo_type: 'Gelo e brina' },
    { meteo_type: 'Grandine' },
    { meteo_type: 'Ondata di calore' },
    { meteo_type: 'Sbalzo termico' },
    { meteo_type: 'Siccità' },
    { meteo_type: 'Vento caldo' },
    { meteo_type: 'Vento forte' }
  ]
};

export const requestTypesArray = {
  requests: [
    'Inserimento raccolta dati comunale',
    'inserimento raccolta dati puntuale',
    'Inserimento relazione metereologica'
  ]
};

export const permissionsArray = {
  permissions: [
    'Inserimento richieste',
    'Modifica richieste personali',
    'Modifica richieste del nodo',
    'Modifica richieste dei sotto nodi',
    'Visualizza lista richieste personali',
    'Visualizza lista richieste del nodo',
    'Visualizza lista richieste dei sotto nodi',
    'Annullamento richieste personali',
    'Annullamento richieste del nodo',
    'Annullamento richieste del sotto nodo',
    'Includi richieste di altri Customer'
  ]
};

export const notificationsArray = {
  notifications: ['Invio notifica al singolo utente', 'Invio notifica di gruppo']
};

export const requestStates = ['SENT', 'TAKING CHARGE', 'DELETED', 'EMITTED'];

export const MAP_STYLES = ['classic', 'mountain', 'satellite', 'light', 'grey', 'dark'];

log.info(STAGE);
