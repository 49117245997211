import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '../views/Admin.vue';
import Customers from '../views/Customers.vue';
import ApiPolicy from '../views/ApiPolicy.vue';
import AppPolicy from '../views/AppPolicy.vue';
import Domain from '../views/Domain.vue';
import Services from '../views/Services.vue';
import Layers from '../views/Layers.vue';
import LayerTranslation from '../components/layers/LayerTranslation.vue';
import CreateService from '../components/services/CreateService.vue';
import ConfigurationServiceStepper from '../components/services/ConfigurationServiceStepper.vue';
import TranslationServiceStepper from '../components/services/TranslationServiceStepper.vue';
import ConfigurationAppPolicy from '../components/policy/ConfigurationAppPolicy.vue';
import TicketingRequestOperatorList from '../components/services/TicketingRequestOperatorList.vue';
import TicketingCustomerRequest from '../components/services/TicketingCustomerRequest.vue';
import TicketingSlotLimits from '../components/services/TicketingSlotLimits.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/api-policy',
    name: 'ApiPolicy',
    component: ApiPolicy
  },
  {
    path: '/app-policy',
    name: 'AppPolicy',
    component: AppPolicy
  },
  {
    path: '/domain',
    name: 'Domain',
    component: Domain
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/layers',
    name: 'Layers',
    component: Layers
  },
  {
    path: '/layer-translation',
    name: 'LayerTranslation',
    component: LayerTranslation
  },
  {
    path: '/create-service',
    name: 'CreateService',
    component: CreateService
  },
  {
    path: '/configuration-stepper/:type/:state',
    name: 'ConfigurationStepper',
    component: ConfigurationServiceStepper
  },
  {
    path: '/translation-stepper/:type',
    name: 'TranslationStepper',
    component: TranslationServiceStepper
  },
  {
    path: '/configuration-app-policy',
    name: 'ConfigurationAppPolicy',
    component: ConfigurationAppPolicy
  },
  {
    path: '/ticketing/:id',
    name: 'TicketingRequestList',
    component: TicketingRequestOperatorList
  },
  {
    path: '/ticketing/:id',
    name: 'TicketingRequest',
    component: TicketingCustomerRequest
  },
  {
    path: '/ticketing-list',
    name: 'TicketingRequestListComplete',
    component: TicketingRequestOperatorList
  },
  {
    path: '/evasion-slot-limits',
    name: 'TicketingSlotLimits',
    component: TicketingSlotLimits
  }
];

const router = new VueRouter({
  routes
});

export default router;
