import {
  getTicketingServiceConfiguration, createTicketingService, editTicketingService, deleteTicketingService,
  createEditTicketingServiceTranslation, getTicketingServiceTranslation, createProfilingTicketingService, listFilteredTicketingRequest,
  getProfilingTicketingService, editProfilingTicketingService, deleteProfilingTicketingService, linkAppUserToProfilingNode, listComuniProvince,
  getLinkedAppUserToProfilingNode, unlinkAppUserToProfilingNode, getTicketingRequestComplete, createTicketingRequest, listDocumentMeteoTypes, linkLocationToTicketingRequest, deleteTicketingRequest, linkDocumentS3ToTicketingRequest, getLinkedDocumentS3toTicketingRequest, deleteDocumentS3, editTicketingRequestState, deleteLinkedLocationToTicketingRequest, editTicketingRequest, listEvasionLimitSlots, editEvasionLimitSlots, listProfilingAppUser, listTicketingRequestLimit, editTicketingRequestLimit
} from '@/api/api.js';
import moment from 'moment-business-days';

export const state = {
  headers: [
    {
      text: 'Application Name',
      value: 'service_name'
    },
    {
      text: 'Application Description',
      value: 'description'
    },
    {
      text: 'Application Type',
      value: 'service_type'
    },
    {
      text: 'Application State',
      value: 'service_state'
    }
  ],
  ticketingRequestHeaders: [
    {
      text: 'Start date',
      value: 'start_date',
      sortable: false
    },
    {
      text: 'End date',
      value: 'end_date',
      sortable: false
    },
    {
      text: 'Latitude',
      value: 'latitude',
      sortable: false
    },
    {
      text: 'Longitude',
      value: 'longitude',
      sortable: false
    },
    {
      text: 'Address',
      value: 'address',
      sortable: false
    },
    {
      text: 'Meteo type',
      value: 'meteo_type',
      sortable: false
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      width: 130
    }
  ],
  appUserHeaders: [
    {
      text: 'APP USER ID',
      align: 'start',
      sortable: false,
      value: 'app_user_id'
    },
    { text: 'Name', value: '_name' },
    { text: 'Surname', value: 'surname' },
    { text: 'Email', value: 'email' },
    { text: 'Username', value: 'username' },
    { text: 'Linked to a Node', value: 'is_already_linked', align: 'center' }
  ],
  linkedAppUserHeaders: [
    {
      text: 'APP USER ID',
      align: 'start',
      sortable: false,
      value: 'app_user_id'
    },
    // { text: "Customer", value: "customer_id" },
    { text: 'Name', value: '_name' },
    { text: 'Surname', value: 'surname' },
    { text: 'Email', value: 'email' },
    { text: 'Username', value: 'username' },
    { text: 'Actions', value: 'actions', sortable: false, width: 130 }
  ],
  downloadS3HeadersAppUser: [
    {
      text: 'File name',
      value: 'file_name',
      sortable: false
    },
    {
      text: 'Type',
      value: 'request_type',
      sortable: false
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      width: 130
    }
  ],
  downloadS3HeadersRadarMeteo: [
    {
      text: 'File name',
      value: 'file_name',
      sortable: false
    },
    {
      text: 'Type',
      value: 'request_type',
      sortable: false
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      width: 130
    }
  ],
  linkedAppUserCredentialsHeaders: [
    { text: 'Customer', value: 'ragione_sociale', sortable: false },
    { text: 'Node', value: 'node_name', sortable: false },
    { text: 'Name', value: '_name', sortable: false },
    { text: 'Surname', value: 'surname', sortable: false },
    { text: 'Email', value: 'email', sortable: false },
    { text: 'Username', value: 'username', sortable: false },
    { text: 'Phone', value: 'username', sortable: false }
  ],
  ticketingRequestLimit: [],
  comuniProvince: [],
  requestTypes: [],
  documentTypes: [],
  meteoTypes: [],
  ticketingServiceConfiguration: [],
  ticketingTranslationServiceConfiguration: {},
  ticketingServiceConfigurationDocumentType: { document_type: [], ticket_id: [] },
  isEditingTranslation: false,
  isProfiling: false,
  showProfilingHierarchy: false,
  showProfilingHierarchyAppUser: false,
  createRequestState: false,
  viewTicketingDetailPopup: false,
  viewTicketingDetailTabs: false,
  selectedRequestTypes: {},
  selectedNotifications: {},
  selectedPermissions: {},
  oldSelectedRequestTypes: {},
  oldSelectedNotifications: {},
  oldSelectedPermissions: {},
  searchFilters: {},
  storeProfilingTicketing: [],
  profilingAppUser: [],
  profilingArray: [],
  deletedProfilingArray: [],
  storeLinkedAppUserToProfilingNode: [],
  ticketingRequestList: [],
  ticketingRequestListFormatted: [],
  documentS3ItemsAppUser: [],
  documentS3ItemsRadarmeteo: [],
  storeDocumentS3Items: [],
  evasionLimitSlots: [],
  request_id: '',
  tempRangeDate: {},
  tempMeteoType: {},
  tempDocType: {},
  tempAddressType: {},
  tempSendMail: {},
  tempLocations: {},
  tempLocationsToRemove: {},
  tempDocS3Radarmeteo: {},
  tempEvasionLimit: {}
};

export const mutations = {
  GET_TICKETING_SERVICES_CONFIGURATION(state, ticketingServicesConfiguration) {
    state.ticketingServiceConfiguration = ticketingServicesConfiguration;
  },
  GET_LIST_COMUNI_PROVINCE(state, listComuniProvince) {
    state.comuniProvince = listComuniProvince;
  },
  GET_LIST_DOCUMENT_TYPES(state, listDocumentMeteoTypes) {
    state.documentTypes = listDocumentMeteoTypes;
  },
  GET_LIST_METEO_TYPES(state, listDocumentMeteoTypes) {
    state.meteoTypes = listDocumentMeteoTypes;
  },
  GET_TRANSLATION_TICKETING_SERVICES_CONFIGURATION(state, ticketingTranslationServicesConfiguration) {
    const arrayToSet = [];

    ticketingTranslationServicesConfiguration.forEach(element => {
      const split_label_code = element.label_code.split('.');

      arrayToSet.push({
        id: split_label_code[2],
        lang: element.lang,
        type: split_label_code[3],
        value: element.label_value
      });
    });

    state.ticketingTranslationServiceConfiguration = arrayToSet;
  },
  GET_PROFILING_TICKETING_SERVICES(state, profilingTicketing) {
    state.storeProfilingTicketing = profilingTicketing;
  },
  GET_LINKED_APP_USER_TO_PROFILING(state, linkedAppUser) {
    state.storeLinkedAppUserToProfilingNode = linkedAppUser;
  },
  setListEvasionLimitSlots(state, data) {
    state.evasionLimitSlots = data;
  },
  setTicketingRequest(state, getTicketingRequest) {
    state.ticketingRequestList = getTicketingRequest;
    state.ticketingRequestListFormatted = JSON.parse(JSON.stringify(getTicketingRequest));
    state.ticketingRequestListFormatted.forEach(item => {
      item.request_date = item.request_date ? moment(item.request_date).format('DD-MM-YYYY') : item.request_date;
      item.evasion_limit = item.evasion_limit ? moment(item.evasion_limit).format('DD-MM-YYYY') : item.evasion_limit;
      item.evasion_date = item.evasion_date ? moment(item.evasion_date).format('DD-MM-YYYY') : item.evasion_date;

      const tempArray = item.full_address.split(', ');
      tempArray.forEach((element, index) => {
        if (element !== '') {
          if (index === 0) {
            item.full_address = `${element}, `;
          } else {
            item.full_address += index % 3 !== 0
              ? `${parseFloat(element)}${index % 3 === 1 ? ';' : ', '}`
              : `${element}, `;
          }
        }
      });

      item.locations.forEach(location => {
        location.latitude = parseFloat(location.latitude);
        location.longitude = parseFloat(location.longitude);
        location.end_date = location.end_date ? moment(location.end_date).format('DD-MM-YYYY') : location.end_date;
        location.start_date = location.start_date ? moment(location.start_date).format('DD-MM-YYYY') : location.start_date;
      });
    });
  },
  setTicketingRequestLimit(state, ticketingRequestLimit) {
    state.ticketingRequestLimit = ticketingRequestLimit;
  },
  setCreateRequestState(state, createRequestState) {
    state.createRequestState = createRequestState;
  },
  setProfilingAppUser(state, profilingAppUser) {
    state.profilingAppUser = profilingAppUser;
  },
  setLinkedDocumentS3(state, getLinkedDocumentS3) {
    state.documentS3ItemsAppUser = [];
    state.documentS3ItemsRadarmeteo = [];
    getLinkedDocumentS3.forEach(item => {
      if (item.document_creator === 'app_user') {
        state.documentS3ItemsAppUser.push(item);
      }

      if (item.document_creator === 'radarmeteo') {
        state.documentS3ItemsRadarmeteo.push(item);
      }
    });
    state.storeDocumentS3Items = JSON.parse(JSON.stringify(getLinkedDocumentS3));
  }
};

export const actions = {
  async storeGetTicketingServiceConfiguration(
    { dispatch, commit },
    service_id
  ) {
    try {
      const dbTicketingServiceConfiguration = await getTicketingServiceConfiguration(
        service_id
      );
      commit(
        'GET_TICKETING_SERVICES_CONFIGURATION',
        dbTicketingServiceConfiguration.data.getTicketingServiceConfiguration
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetTicketingServiceConfiguration',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createTicketingServiceAction({ dispatch }, data) {
    try {
      await createTicketingService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing configuration created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createTicketingServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editTicketingServiceAction({ dispatch }, data) {
    try {
      await editTicketingService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing configuration edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editTicketingServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteTicketingServiceAction({ dispatch }, data) {
    try {
      await deleteTicketingService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing configuration deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteTicketingServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createEditTicketingServiceTranslationAction({ dispatch }, data) {
    try {
      await createEditTicketingServiceTranslation(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing configuration translated',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createEditTicketingServiceTranslationAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeGetTicketingServiceTranslation({ dispatch, commit }, data) {
    try {
      const getTicketingServiceTranslationResp = await getTicketingServiceTranslation(data);
      commit(
        'GET_TRANSLATION_TICKETING_SERVICES_CONFIGURATION',
        getTicketingServiceTranslationResp.data.getTicketingServiceTranslation
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetTicketingServiceTranslation',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createProfilingTicketingServiceAction({ dispatch }, data) {
    try {
      await createProfilingTicketingService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing profiling created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createProfilingTicketingServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeGetProfilingTicketingService(
    { dispatch, commit },
    service_id
  ) {
    try {
      const dbProfilingTicketingService = await getProfilingTicketingService(
        service_id
      );
      commit(
        'GET_PROFILING_TICKETING_SERVICES',
        dbProfilingTicketingService.data.getProfilingTicketingService
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetProfilingTicketingService',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editProfilingTicketingServiceAction({ dispatch }, data) {
    try {
      await editProfilingTicketingService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing profiling edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editProfilingTicketingServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteProfilingTicketingServiceAction({ dispatch }, data) {
    try {
      await deleteProfilingTicketingService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing profiling deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteProfilingTicketingServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async linkAppUserToProfilingNodeAction({ dispatch }, data) {
    try {
      await linkAppUserToProfilingNode(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'App user linked',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'linkAppUserToProfilingNodeAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeGetLinkedAppUserToProfilingNode({ dispatch, commit }, node_id) {
    try {
      const resp = await getLinkedAppUserToProfilingNode(node_id);
      commit('GET_LINKED_APP_USER_TO_PROFILING', resp.data.getLinkedAppUserToProfilingNode);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetLinkedAppUserToProfilingNode',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async unlinkAppUserToProfilingNodeAction({ dispatch }, data) {
    try {
      await unlinkAppUserToProfilingNode(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'App user unlinked',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'unlinkAppUserToProfilingNodeAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeListEvasionLimitSlots({ commit, dispatch }, data) {
    try {
      const resp = await listEvasionLimitSlots(data);
      commit('setListEvasionLimitSlots', resp.data.listEvasionLimitSlots);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeListEvasionLimitSlots',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editEvasionLimitSlotsAction({ dispatch }, data) {
    try {
      await editEvasionLimitSlots(data);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editEvasionLimitSlotsAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async listProfilingAppUserAction({ commit, dispatch }, data) {
    try {
      const resp = await listProfilingAppUser(data);
      commit('setProfilingAppUser', resp.data.listProfilingAppUser);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'listProfilingAppUserAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async reorganizeSlots({ dispatch, state }, evasion_limit) {
    if (evasion_limit !== '') {
      const datesToCheck = [...Array(2)].map((e, i) => moment().add(i, 'years').format('YYYY-'));
      await dispatch('storeListEvasionLimitSlots', datesToCheck);

      const next15Slots = [...Array(15)].map((e, i) => moment(evasion_limit).businessAdd(i + 1).format('YYYY-MM-DD'));
      const filteredSlots = state.evasionLimitSlots.filter(d => next15Slots.includes(d.evasion_limit));
      const slotsToMove = [];

      for (const slot of filteredSlots) {
        const checkCanBeMoved = slot.can_be_moved > 0;
        const checkFreeSlots = slot.total_slots < slot.max_slots;
        const checkExceededSlots = slot.total_slots > slot.max_slots;
        const checkZeroSlots = slot.total_slots === 0 && slot.max_slots === 0;

        if (checkZeroSlots) {
          continue;
        }

        if (checkCanBeMoved) {
          slotsToMove.push(slot.evasion_limit);
        }

        if (!checkCanBeMoved || checkFreeSlots || checkExceededSlots) {
          break;
        }
      }

      return slotsToMove;
    }

    return [];
  },
  async storeGetTicketingRequestComplete({ commit, dispatch }, data) {
    try {
      const resp = await getTicketingRequestComplete(data);

      resp.data.getTicketingRequestComplete.forEach(report => {
        report.meteo_type = [];
        report.locations.forEach(item => report.meteo_type.push(item.meteo_type.split(',')[0]));
      });

      commit('setTicketingRequest', resp.data.getTicketingRequestComplete);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetTicketingRequestComplete',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeListFilteredTicketingRequest({ commit, dispatch }, data) {
    const payload = { ...data };
    delete payload.latitude;
    delete payload.longitude;
    delete payload.radius;

    try {
      const resp = await listFilteredTicketingRequest(payload);

      resp.data.listFilteredTicketingRequest.forEach(report => {
        report.meteo_type = [];
        report.locations.forEach(item => report.meteo_type.push(item.meteo_type.split(',')[0]));
      });

      commit('setTicketingRequest', resp.data.listFilteredTicketingRequest);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeListFilteredTicketingRequest',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createTicketingRequestAction({ commit, dispatch }, data) {
    try {
      await createTicketingRequest(data);
      commit('setCreateRequestState', true);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing request send',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      commit('setCreateRequestState', false);
      dispatch(
        'storeToastMessage',
        {
          text: 'createTicketingRequestAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async linkLocationToTicketingRequestAction({ dispatch }, data) {
    try {
      await linkLocationToTicketingRequest(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Location linked to request',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'linkLocationToTicketingRequestAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteTicketingRequestAction({ dispatch, commit }, data) {
    try {
      await deleteTicketingRequest(data);
      commit('setCreateRequestState', true);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing request canceled',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      commit('setCreateRequestState', false);
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteTicketingRequestAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async linkDocumentS3ToTicketingRequestAction({ dispatch }, data) {
    try {
      await linkDocumentS3ToTicketingRequest(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Document S3 linked to request',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'linkDocumentS3ToTicketingRequestAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeGetLinkedDocumentS3toTicketingRequest({ commit, dispatch }, data) {
    try {
      const resp = await getLinkedDocumentS3toTicketingRequest(data);
      commit('setLinkedDocumentS3', resp.data.getLinkedDocumentS3toTicketingRequest);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetLinkedDocumentS3toTicketingRequest',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteDocumentS3Action({ dispatch }, data) {
    try {
      await deleteDocumentS3(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Document S3 deleted from DB',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteDocumentS3',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editTicketingRequestStateAction({ commit, dispatch }, data) {
    try {
      await editTicketingRequestState(data);
      commit('setCreateRequestState', true);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing request state edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      commit('setCreateRequestState', false);
      dispatch(
        'storeToastMessage',
        {
          text: 'editTicketingRequestStateAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteLinkedLocationToTicketingRequestAction({ dispatch }, data) {
    try {
      await deleteLinkedLocationToTicketingRequest(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Location linked to request deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteLinkedLocationToTicketingRequestAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editTicketingRequestAction({ commit, dispatch }, data) {
    try {
      await editTicketingRequest(data);
      commit('setCreateRequestState', true);
      dispatch(
        'storeToastMessage',
        {
          text: 'Ticketing request edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      commit('setCreateRequestState', false);
      dispatch(
        'storeToastMessage',
        {
          text: 'editTicketingRequestAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async listTicketingRequestLimitAction({ commit, dispatch }, data) {
    try {
      const resp = await listTicketingRequestLimit(data);
      commit('setTicketingRequestLimit', resp.data.listTicketingRequestLimit);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'listTicketingRequestLimit',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editTicketingRequestLimitAction({ dispatch }, data) {
    try {
      await editTicketingRequestLimit(data);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'listTicketingRequestLimit',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async listComuniProvinceAction({ commit, dispatch }, is_prov) {
    try {
      const resp = await listComuniProvince(is_prov);
      commit('GET_LIST_COMUNI_PROVINCE', resp.data.listComuniProvince.list);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'listComuniProvinceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async listDocumentMeteoTypesAction({ commit, dispatch }, type) {
    try {
      const resp = await listDocumentMeteoTypes(type);

      if (type === 'document') {
        commit('GET_LIST_DOCUMENT_TYPES', resp.data.listDocumentMeteoTypes.list);
      }

      if (type === 'meteo') {
        commit('GET_LIST_METEO_TYPES', resp.data.listDocumentMeteoTypes.list);
      }
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: `listDocumentMeteoTypesAction: ${type}`,
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};
