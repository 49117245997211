<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-stepper
      id="workspace-stepper"
      v-model="e6"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="e6 > 1"
          step="1"
          dark
          color="color_green"
        >
          Translate Application Name and Description
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="e6 > 2"
          step="2"
          dark
          color="color_green"
        >
          Translate Application Structure
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>
      <v-form>
        <v-stepper-items>
          <v-stepper-content step="1">
            <div
              v-if="!isLanguageSelected"
              class="mx-auto mt-6 file-input-width"
            >
              <v-select
                v-model="selectedLanguage"
                label="Select Language"
                :items="setLanguages"
                :value="setLanguages.text"
                @change="changeSelectedLanguage(selectedLanguage)"
              />
            </div>
            <v-row v-if="isLanguageSelected">
              <v-col cols="6">
                <create-service
                  :service-form="services.storeServiceForm"
                  :is-editing="true"
                  :is-translating="services.isTranslating"
                  :disable-form="true"
                />
              </v-col>
              <v-col cols="6">
                <create-service
                  :service-form="services.storeTranslationServiceForm"
                  :is-editing="true"
                  :is-translating="services.isTranslating"
                  :selected-language="selectedLanguageText"
                />
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col cols="1">
                <v-btn
                  dark
                  color="warning"
                  @click="$router.go(-1)"
                >
                  Close
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col cols="2">
                <v-btn
                  light
                  color="color_green"
                  style="color:white"
                  :disabled="
                    services.storeTranslationServiceForm.service_name == ''
                  "
                  @click="confirmStepOne"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content
            v-if="isLanguageSelected && selectedLanguageValue != ''"
            step="2"
          >
            <div v-if="routeType == 'URL' || routeType == 'DOC' || routeType == 'REPORT-HUB'">
              <translation-configuration-hierarchy-service
                :selected-language-text="selectedLanguageText"
                :hierarchy-items="hierarchyItems"
                :route-type="routeType"
              />
            </div>
            <!-- <div v-else-if="routeType == 'TICKET'">
              <translation-configuration-ticketing-service
                :selected-language-text="selectedLanguageText"
                :selected-language-value="selectedLanguageValue"
              />
            </div> -->

            <v-row class="mt-6">
              <v-col cols="1">
                <v-btn
                  dark
                  color="warning"
                  @click="close()"
                >
                  Close
                </v-btn>
              </v-col>
              <v-spacer />
              <v-btn
                class="mt-3"
                text
                @click="e6 = 1"
              >
                Cancel
              </v-btn>
              <v-col cols="2">
                <v-btn
                  dark
                  color="color_green"
                  @click="confirmTranslation"
                >
                  Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-form>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { LANGUAGES } from '@/constants.js';
import { findItemNested, nestHierarchyItems } from '@/utils.js';

export default {
  components: {
    TranslationConfigurationHierarchyService: () =>
      import('@/components/services/TranslationConfigurationHierarchyService'),
    CreateService: () => import('@/components/services/CreateService')
    // TranslationConfigurationTicketingService: () => import('@/components/services/TranslationConfigurationTicketingService')
  },
  data() {
    return {
      search: '',
      overlay: false,
      loading: false,
      isLanguageSelected: false,
      e6: 1,
      selectedLanguage: '',
      selectedLanguageText: '',
      selectedLanguageValue: '',
      hierarchyItems: []
    };
  },
  computed: {
    ...mapState(['services', 'ticketing']),
    setLanguages() {
      return LANGUAGES;
    }
  },
  watch: {
    $route() {}
  },
  created() {
    this.routeType = this.$route.params.type;

    if (this.services.service_id === '') {
      this.$router.go(-1);
    }
  },
  methods: {
    ...mapActions([
      'storeGetUrlServiceConfiguration',
      'storeGetDocumentalServiceConfiguration',
      'storeGetReportHubServiceConfiguration',
      'createUrlServiceTranslationAction',
      'createTranslationServiceAction',
      'createDocumentalServiceTranslationAction',
      'createReportHubServiceTranslationAction',
      'storeTranslationServices',
      'editTranslationServiceAction',
      'storeGetTranslationNodes',
      'editTranslationNodesAction'
      // 'createTicketingServiceTranslationAction',
      // 'editTicketingServiceTranslationAction'
    ]),
    async changeSelectedLanguage(item) {
      const language = item.split('-');
      this.isLanguageSelected = true;
      this.selectedLanguageValue = language[0];
      this.selectedLanguageText = language[1];
      this.services.storeTranslationServiceForm = {
        service_name: '',
        description: ''
      };
      const dataService = {
        service_id: this.services.service_id,
        lang: this.selectedLanguageValue,
        service_name: this.services.service_name
      };
      this.overlay = true;
      await this.storeTranslationServices(dataService);
      this.overlay = false;

      const dataNodes = this.services.hierarchyItemsLinear.map(item => {
        return {
          node_id: item.node_id,
          lang: this.selectedLanguageValue
        };
      });
      this.overlay = true;
      await this.storeGetTranslationNodes(dataNodes);
      this.overlay = false;
    },
    async getServicesConfiguration() {
      if (this.routeType == 'URL') {
        this.overlay = true;
        await this.storeGetUrlServiceConfiguration(this.services.service_id);
        this.overlay = false;
      }

      if (this.routeType == 'DOC') {
        this.overlay = true;
        await this.storeGetDocumentalServiceConfiguration(this.services.service_id);
        this.overlay = false;
      }

      if (this.routeType == 'REPORT-HUB') {
        this.overlay = true;
        await this.storeGetReportHubServiceConfiguration(this.services.service_id);
        this.overlay = false;
      }

      if (this.services.isEditingTranslationNodes) {
        this.services.hierarchyItemsLinear.filter(ita => {
          return this.services.storeTranslationNodes.map(lang => {
            if (lang.node_name != null && ita.node_id == lang.node_id) {
              ita.node_name = lang.node_name;
            }

            if (lang.node_description != null && ita.node_id == lang.node_id) {
              ita.node_description = lang.node_description;
            }
          });
        });
        this.hierarchyItems = nestHierarchyItems(
          this.services.hierarchyItemsLinear
        );
        this.services.hierarchyItems = nestHierarchyItems(
          this.services.hierarchyItemsLinear
        );
      } else {
        this.hierarchyItems = nestHierarchyItems(
          this.services.hierarchyItemsLinear
        );
      }
    },
    confirmStepOne() {
      this.isLanguageSelected = true;
      this.services.storeTranslationServiceForm.lang = this.selectedLanguageValue;
      this.services.storeTranslationServiceForm.service_id = this.services.storeServiceForm.service_id;
      this.services.storeTranslationServiceForm.service_type = this.services.storeServiceForm.service_type;

      this.getServicesConfiguration();
      this.e6 = 2;
    },
    async confirmTranslation() {
      this.services.hierarchyItemsLinear = [];

      if (!this.services.isEditingTranslation) {
        this.overlay = true;
        await this.createTranslationServiceAction(
          this.services.storeTranslationServiceForm
        );
        this.overlay = false;
      } else {
        this.overlay = true;
        await this.editTranslationServiceAction(
          this.services.storeTranslationServiceForm
        );
        this.overlay = false;
      }

      findItemNested(
        this.services.hierarchyItems,
        'children',
        this.services.hierarchyItemsLinear,
        this.routeType
      );

      this.services.hierarchyItemsLinear.forEach(item => {
        item.lang = this.selectedLanguageValue;
      });

      if (this.services.existingServicesConfiguration.length > 0) {
        if (this.routeType == 'URL') {
          if (this.services.isEditingTranslationNodes) {
            const dataNodes = this.services.hierarchyItemsLinear.map(item => {
              return {
                node_id: item.node_id,
                node_name: item.node_name,
                node_description: item.node_description,
                lang: this.selectedLanguageValue
              };
            });
            this.overlay = true;
            await this.editTranslationNodesAction(dataNodes);
            this.overlay = false;
          } else {
            this.overlay = true;
            await this.createUrlServiceTranslationAction(
              this.services.hierarchyItemsLinear
            );
            this.overlay = false;
          }
        }

        if (this.routeType == 'DOC') {
          if (this.services.isEditingTranslationNodes) {
            const dataNodes = this.services.hierarchyItemsLinear.map(item => {
              return {
                node_id: item.node_id,
                node_name: item.node_name,
                node_description: item.node_description,
                lang: this.selectedLanguageValue
              };
            });
            this.overlay = true;
            await this.editTranslationNodesAction(dataNodes);
            this.overlay = false;
          } else {
            this.overlay = true;
            await this.createDocumentalServiceTranslationAction(
              this.services.hierarchyItemsLinear
            );
            this.overlay = false;
          }
        }

        if (this.routeType == 'REPORT-HUB') {
          if (this.services.isEditingTranslationNodes) {
            const dataNodes = this.services.hierarchyItemsLinear.map(item => {
              return {
                node_id: item.node_id,
                node_name: item.node_name,
                node_description: item.node_description,
                lang: this.selectedLanguageValue
              };
            });
            this.overlay = true;
            await this.editTranslationNodesAction(dataNodes);
            this.overlay = false;
          } else {
            this.overlay = true;
            await this.createReportHubServiceTranslationAction(
              this.services.hierarchyItemsLinear
            );
            this.overlay = false;
          }
        }
      }

      // if (this.routeType == 'TICKET') {
      //   const translationDocumentType = this.ticketing.requestTypes.documentTypes.map(
      //     document => {
      //       document.lang = this.selectedLanguageValue;
      //       document.service_id = this.services.service_id;

      //       return document;
      //     }
      //   );
      //   const translationMeteoType = this.ticketing.requestTypes.meteoTypes.map(
      //     meteo => {
      //       meteo.lang = this.selectedLanguageValue;
      //       meteo.service_id = this.services.service_id;

      //       return meteo;
      //     }
      //   );

      //   if (
      //     this.ticketing.isEditingTranslation &&
      //     this.ticketing.ticketingServiceConfiguration.length > 0
      //   ) {
      //     this.overlay = true;
      //     await this.editTicketingServiceTranslationAction(
      //       translationDocumentType.concat(translationMeteoType)
      //     );
      //     this.overlay = false;
      //   } else {
      //     this.overlay = true;
      //     await this.createTicketingServiceTranslationAction(
      //       translationDocumentType.concat(translationMeteoType)
      //     );
      //     this.overlay = false;
      //   }
      // }

      this.services.isTranslating = false;
      this.$router.go(-1);
    },
    close() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>

<style scoped>
.file-input-width {
  width: 600px;
}
</style>
