<template>
  <v-container>
    <v-row>
      <v-col>
        <service-table
          @createServiceEvent="createServiceEvent"
          @editServiceEvent="editServiceEvent($event)"
          @configurationServiceEvent="configurationServiceEvent($event)"
          @translationServiceEvent="translationServiceEvent($event)"
        />
      </v-col>
    </v-row>
    <div v-if="services.showCreateService">
      <v-row>
        <v-col>
          <create-service
            :service-form="serviceForm"
            :is-editing="isEditing"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
    ServiceTable: () => import('@/components/services/ServiceTable'),
    CreateService: () => import('@/components/services/CreateService')
  },
  data() {
    return {
      showCreateService: false,
      isEditing: false,
      serviceForm: {}
    };
  },
  computed: {
    ...mapState(['services', 'ticketing']),
    target() {
      const value = 9999;

      return value;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      };
    }
  },
  methods: {
    createServiceEvent() {
      this.services.showCreateService = true;
      this.services.isTranslating = false;
      this.isEditing = false;
      this.serviceForm = {
        description: '',
        service_name: '',
        service_type: ''
      };
      this.$vuetify.goTo(this.target, this.options);
    },
    editServiceEvent(item) {
      this.services.showCreateService = true;
      this.services.isTranslating = false;
      this.services.storeServiceForm = item;
      this.isEditing = true;
      this.serviceForm = JSON.parse(JSON.stringify(item));
      this.$vuetify.goTo(this.target, this.options);
    },
    configurationServiceEvent(item) {
      this.$router.push({
        name: 'ConfigurationStepper',
        params: { type: item.service_type, state: item.service_state }
      });
      this.services.isTranslating = false;
      this.ticketing.isProfiling = false;
      this.services.storeServiceForm = JSON.parse(JSON.stringify(item));
      this.services.service_id = item.service_id;
    },
    translationServiceEvent(item) {
      this.$router.push({
        name: 'TranslationStepper',
        params: { type: item.service_type }
      });
      this.services.isTranslating = true;
      this.ticketing.isProfiling = false;
      this.services.service_id = item.service_id;
      this.services.service_name = item.service_name;
      this.services.storeServiceForm = JSON.parse(JSON.stringify(item));
    }
  }
};
</script>
