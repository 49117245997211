/* eslint-disable no-undef */
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
// import ImageWMS from 'ol/source/ImageWMS';
import Static from 'ol/source/ImageStatic';
import { Image as ImageLayer, Tile as TileLayer } from 'ol/layer';
import Projection from 'ol/proj/Projection';
// import { fromLonLat } from 'ol/proj';
// import Style from 'ol/style/Style';


// --------------------- STATE -----------------------------

export const state = {
  latitude: '',
  longitude: '',
  address: '',
  addressProv: '',
  ticketing_formatted_address: '',
  OLmap: null,
  OLLayers: [
    new TileLayer({
      source: new OSM()
    }),
    new TileLayer({
      source: new XYZ({
        url: 'http://mt0.google.com/vt/lyrs=m&hl=it&x={x}&y={y}&z={z}'
      })
    })
  ]
};

// --------------------- MUTATIONS ----------------------------

export const mutations = {};

// ------------------------ ACTIONS ---------------------------

export const actions = {
  initiateOLMapAction({ state, dispatch }) {
    try {
      state.OLmap = new Map({
        layers: state.OLLayers,
        target: 'map',
        view: new View({
          projection: 'EPSG:3857',
          center: [12.282715, 31.701948],
          zoom: 6.9
        })
      });
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'initiateOLMapAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async setOLLayers({ state }, data) {
    const layers = state.OLmap.getLayers();
    const layersArray = layers.getArray();
    layersArray.push(
      new ImageLayer({
        rotation: -10,
        // extent: transform([-16.359999, 24.780000, 33.679999, 63.539999]),
        // style: new Style({
        //   anchor: [0, 0],
        //   anchorXUnits: "pixels",
        //   anchorYUnits: "pixels",
        //   offset: [0, 0],
        //   src: data.url
        // }),
        // source: new ImageWMS({
        //   url: data.url,
        //   params: data.params,
        //   ratio: 1,
        //   projection: 'EPSG:3857',
        //   imageScale: [1, 1]
        // })
        source: new Static({
          url: data.url,
          projection: new Projection({
            code: 'EPSG:3857',
            units: 'pixels',
            extent: transform([-16.359999, 24.780000, 33.679999, 63.539999])
          }),
          imageExtent: transform([-16.359999, 24.780000, 33.679999, 63.539999])
        })
      })
    );
    state.OLmap.layers = layersArray;
  },
  ticketingReverseGeocoding({ state }, coord) {
    const [longitude, latitude] = coord;
    const latlng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude)
    };

    const reverseGeocoding = new google.maps.Geocoder();
    reverseGeocoding
      .geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          let place = '';
          let prov = '';
          response.results[0].address_components.forEach(item => {
            if (item.types[0] === 'administrative_area_level_3') {
              place += item.short_name;
            }

            if (item.types[0] === 'administrative_area_level_2') {
              if (place !== '') {
                place += ` (${item.short_name})`;
              }

              prov = `${item.long_name.replace('Città Metropolitana di ', '').replace('Provincia di ', '')} (${item.short_name})`;
            }
          });

          if (place === '') {
            if (response.results[0].address_components[0].types[0] === 'plus_code') {
              const toRemove = response.results[0].address_components[0].long_name;
              place = response.results[0].formatted_address.replace(toRemove, '');
            } else {
              place = response.results[0].formatted_address;
            }
          }

          state.ticketing_formatted_address = response.results[0].formatted_address;
          state.address = place || 'SNC';
          state.addressProv = prov || 'SNC';
        } else {
          window.alert('No results found');
        }
      })
      .catch((e) => window.alert('Geocoder failed due to: ' + e));
  }
};

export const getters = {};
