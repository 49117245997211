<template>
  <v-container>
    <v-row>
      <v-col>
        <admin-table
          @toParent="forceRerender"
          @closeDialog="closeEdit"
          @openDialog="openEdit"
        />
      </v-col>
    </v-row>
    <v-row v-if="admin.editAdmin == true">
      <v-col>
        <v-card>
          <admin-form
            :key="componentKey"
            @closeDialog="closeEdit"
          />
          <v-card-actions>
            <v-btn
              class="mx-auto"
              min-width="350"
              outlined
              color="primary"
              @click="closeEdit"
            >
              Close edit
              <v-icon right>
                mdi-close-box-outline
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    AdminTable: () => import('@/components/admin/AdminTable'),
    AdminForm: () => import('@/components/admin/AdminForm')
  },

  computed: {
    ...mapState(['admin'])
  },

  methods: {
    ...mapActions(['changeEditAdmin', 'storeCustomerCRMID']),
    closeEdit() {
      this.changeEditAdmin(false);
    },
    openEdit() {
      this.changeEditAdmin(true);
    },
    itemFromTable() {
      this.forceRerender();
    },
    forceRerender() {
      this.componentKey += 1;
    }
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
