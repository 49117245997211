// -------------------- ADMIN ----------------------

export const newAdmin = /* GraphQL */ `
  mutation newAdmin(
    $username: String!
    $_name: String!
    $surname: String!
    $email: String!
  ) {
    createAdminUser(
      input: {
        username: $username
        name: $_name
        surname: $surname
        email: $email
      }
    ) {
      username
      _name
      surname
      email
    }
  }
`;

export const deleteAdminUser = /* GraphQL */ `
  mutation deleteAdminUser($username: String!) {
    deleteAdminUser(input: { username: $username }) {
      username
      _state
    }
  }
`;

// -------------------- CUSTOMER ----------------------

export const newCustomer = /* GraphQL */ `
  mutation newCustomer(
    $crm_id: String!
    $ragione_sociale: String!
    $c_description: String!
    $latitude: String
    $longitude: String
    $zoom: Int
    $map_style: String
    $state: Boolean
    $logo: String
  ) {
    createCustomer(
      input: {
        crm_id: $crm_id
        ragione_sociale: $ragione_sociale
        c_description: $c_description
        latitude: $latitude
        longitude: $longitude
        zoom: $zoom
        map_style: $map_style
        state: $state
        logo: $logo
      }
    ) {
      crm_id
      ragione_sociale
      c_description
      latitude
      longitude
      zoom
      map_style
      state
      logo
    }
  }
`;

export const updateCustomer = /* GraphQL */ `
  mutation updateCustomer(
    $crm_id: String!
    $ragione_sociale: String!
    $c_description: String!
    $latitude: String
    $longitude: String
    $zoom: Int
    $map_style: String
  ) {
    updateCustomer(
      input: {
        crm_id: $crm_id
        ragione_sociale: $ragione_sociale
        c_description: $c_description
        latitude: $latitude
        longitude: $longitude
        zoom: $zoom
        map_style: $map_style
      }
    ) {
      crm_id
      ragione_sociale
      c_description
      latitude
      longitude
      zoom
      map_style
    }
  }
`;

export const deleteCustomer = /* GraphQL */ `
  mutation deleteCustomer($crm_id: String!) {
    deleteCustomer(input: { crm_id: $crm_id }) {
      crm_id
      ragione_sociale
      c_description
      latitude
      longitude
      zoom
      logo
    }
  }
`;

export const updateLogoCustomer = /* GraphQL */ `
mutation updateLogoCustomer($input: LogoCustomerInput!) {
  updateLogoCustomer(input: $input) {
    service_id
  }
}
`;

export const stateCustomer = /* GraphQL */ `
  mutation stateCustomer($crm_id: String! $state: Boolean!) {
    stateCustomer(input: { crm_id: $crm_id, state: $state }) {
      service_id
    }
  }
`;

export const createCustomerManuals = /* GraphQL */ `
mutation createCustomerManuals($input: CustomerManualsInput!) {
  createCustomerManuals(input: $input) {
    manual_s3_path
    label_code
    label_value
    customer_id
  }
}
`;

export const deleteCustomerManuals = /* GraphQL */ `
mutation deleteCustomerManuals($input: CustomerManualsInput!) {
  deleteCustomerManuals(input: $input) {
    manual_s3_path
    label_code
    customer_id
  }
}
`;

export const createDefaultService = /* GraphQL */ `
mutation createDefaultService($input: DefaultServiceInput!) {
  createDefaultService(input: $input) {
    default_id
    customer_id
  }
}
`;

export const updateDefaultService = /* GraphQL */ `
mutation updateDefaultService($input: DefaultServiceInput!) {
  updateDefaultService(input: $input) {
    default_id
    customer_id
  }
}
`;

export const deleteDefaultService = /* GraphQL */ `
mutation deleteDefaultService($input: DeleteDefaultServiceInput!) {
  deleteDefaultService(input: $input) {
    default_id
    customer_id
  }
}
`;

export const createEditTranslation = /* GraphQL */ `
mutation createEditTranslation($input: TranslationInput!) {
  createEditTranslation(input: $input) {
    label_code
    label_value
    lang
  }
}
`;

// -------------------- APP USER ---------------------

export const newAppUser = /* GraphQL */ `
  mutation newAppUser($input: [CreateAppUserInput]!) {
    createAppUser(input: $input) {
      created {
        app_user_id
        username
        _name
        surname
        email
        phone
        language
        _state
        user_map_style
      }
      not_created {
        app_user_id
        username
        _name
        surname
        email
        phone
        language
        _state
        user_map_style
      }
    }
  }
`;

export const updateAppUser = /* GraphQL */ `
  mutation updateAppUser(
    $app_user_id: String!
    $name: String!
    $surname: String!
    $email: String!
    $phone: String
    $language: String
    $user_map_style: String
  ) {
    updateAppUser(
      input: {
        app_user_id: $app_user_id
        name: $name
        surname: $surname
        email: $email
        phone: $phone
        language: $language
        user_map_style: $user_map_style
      }
    ) {
      service_id
    }
  }
`;

export const deleteAppUser = /* GraphQL */ `
  mutation deleteAppUser($app_user_id: String!, $username: String, $cognito_user_created: Boolean) {
    deleteAppUser(
      input: {
        app_user_id: $app_user_id
        username: $username
        cognito_user_created: $cognito_user_created
        }) {
      app_user_id
      username
      _state
    }
  }
`;

export const changeAppUserState = /* GraphQL */ `
  mutation changeAppUserState(
    $app_user_id: String!
    $username: String!
    $_state: Boolean!
    $cognito_user_created: Boolean!
    $_name: String!
    $email: String!
    ) {
    changeAppUserState(
      input: {
        app_user_id: $app_user_id
        username: $username
        _state: $_state
        cognito_user_created: $cognito_user_created
        _name: $_name
        email: $email
      }) {
      service_id
    }
  }
`;

// -------------------- TECH USER ------------------------

export const newTechUser = /* GraphQL */ `
  mutation newTechUser(
    $username: String!
    $email: String!
    $policy_id: Int!
    $customer_id: String!
  ) {
    createTechUser(
      input: {
        username: $username
        email: $email
        policy_id: $policy_id
        customer_id: $customer_id
      }
    ) {
      username
      email
      _state
      policy_id
    }
  }
`;

export const updateTechUser = /* GraphQL */ `
  mutation updateTechUser(
    $username: String!
    $email: String!
    $policy_id: Int!
  ) {
    updateTechUser(
      input: { username: $username, email: $email, policy_id: $policy_id }
    ) {
      username
      _state
    }
  }
`;

export const deleteTechUser = /* GraphQL */ `
  mutation deleteTechUser($username: String!) {
    deleteTechUser(input: { username: $username }) {
      username
      _state
    }
  }
`;

export const changeTechUserState = /* GraphQL */ `
  mutation changeTechUserState($username: String!, $state: Boolean!) {
    changeTechUserState(input: { username: $username, state: $state }) {
      username
      _state
    }
  }
`;

// -------------------- POLICY ------------------------
export const newPolicy = /* GraphQL */ `
  mutation newPolicy($policy_name: String!, $policy_description: String) {
    createPolicy(
      input: {
        policy_name: $policy_name
        policy_description: $policy_description
      }
    ) {
      policy_id
      policy_name
      policy_description
    }
  }
`;

export const updatePolicy = /* GraphQL */ `
  mutation updatePolicy(
    $policy_id: Int!
    $policy_name: String!
    $policy_description: String
  ) {
    updatePolicy(
      input: {
        policy_id: $policy_id
        policy_name: $policy_name
        policy_description: $policy_description
      }
    ) {
      policy_id
      policy_name
      policy_description
    }
  }
`;

export const deletePolicy = /* GraphQL */ `
  mutation deletePolicy($policy_id: Int!) {
    deletePolicy(input: { policy_id: $policy_id }) {
      policy_id
      policy_name
      policy_description
    }
  }
`;

// -------------------- WORKSPACE ------------------------
export const newWorkspace = /* GraphQL */ `
  mutation newWorkspace(
    $workspace_name: String!
    $n_calls: Int!
    $start_time: String!
    $stop_time: String!
    $date_type: String!
    $policy_id: Int!
  ) {
    createWorkspace(
      input: {
        workspace_name: $workspace_name
        n_calls: $n_calls
        start_time: $start_time
        stop_time: $stop_time
        date_type: $date_type
        policy_id: $policy_id
      }
    ) {
      workspace_name
      n_calls
      start_time
      stop_time
      date_type
      policy_id
    }
  }
`;

export const updateWorkspace = /* GraphQL */ `
  mutation updateWorkspace(
    $workspace_name: String!
    $n_calls: Int!
    $start_time: String!
    $stop_time: String!
    $date_type: String!
    $policy_id: Int!
  ) {
    updateWorkspace(
      input: {
        workspace_name: $workspace_name
        n_calls: $n_calls
        start_time: $start_time
        stop_time: $stop_time
        date_type: $date_type
        policy_id: $policy_id
      }
    ) {
      workspace_name
      n_calls
      start_time
      stop_time
      date_type
      policy_id
    }
  }
`;

export const deleteWorkspace = /* GraphQL */ `
  mutation deleteWorkspace($workspace_name: String!, $policy_id: Int!) {
    deleteWorkspace(
      input: { workspace_name: $workspace_name, policy_id: $policy_id }
    ) {
      workspace_name
      policy_id
    }
  }
`;

// ----------------- LAYER ------------------

export const createLayer = /* GraphQL */ `
  mutation createLayer(
    $workspace_name: String!
    $policy_id: Int!
    $layer_name: String!
  ) {
    createLayer(
      input: {
        workspace_name: $workspace_name
        policy_id: $policy_id
        layer_name: $layer_name
      }
    ) {
      workspace_name
      policy_id
      layer_name
    }
  }
`;

export const deleteLayer = /* GraphQL */ `
  mutation deleteLayer(
    $workspace_name: String!
    $policy_id: Int!
    $layer_name: String!
  ) {
    deleteLayer(
      input: {
        workspace_name: $workspace_name
        policy_id: $policy_id
        layer_name: $layer_name
      }
    ) {
      workspace_name
      policy_id
      layer_name
    }
  }
`;

// ----------------- WS-DOMAIN ------------------

export const addDomain = /* GraphQL */ `
  mutation addDomain(
    $workspace_name: String!
    $policy_id: Int!
    $domain_id: Int!
  ) {
    addDomainToWS(
      input: {
        workspace_name: $workspace_name
        policy_id: $policy_id
        domain_id: $domain_id
      }
    ) {
      domain_id
      domain_name
    }
  }
`;

export const deleteDomainWS = /* GraphQL */ `
  mutation deleteDomainWS(
    $workspace_name: String!
    $policy_id: Int!
    $domain_id: Int!
  ) {
    deleteDomainFromWS(
      input: {
        workspace_name: $workspace_name
        policy_id: $policy_id
        domain_id: $domain_id
      }
    ) {
      workspace_name
      domain_id
    }
  }
`;

// ---------------------- DOMAIN ---------------------
export const newDomain = /* GraphQL */ `
  mutation newDomain(
    $domain_id: Int!
    $domain_name: String!
    $domain_description: String
    $polygon: String!
  ) {
    createDomain(
      input: {
        domain_id: $domain_id
        domain_name: $domain_name
        domain_description: $domain_description
        polygon: $polygon
      }
    ) {
      domain_id
      domain_name
      domain_description
      polygon
    }
  }
`;

export const updateDomain = /* GraphQL */ `
  mutation updateDomain(
    $domain_id: Int!
    $domain_name: String!
    $domain_description: String
    $polygon: String!
  ) {
    updateDomain(
      input: {
        domain_id: $domain_id
        domain_name: $domain_name
        domain_description: $domain_description
        polygon: $polygon
      }
    ) {
      domain_id
      domain_name
      domain_description
      polygon
    }
  }
`;

export const deleteDomain = /* GraphQL */ `
  mutation deleteDomain($domain_id: Int!) {
    deleteDomain(input: { domain_id: $domain_id }) {
      domain_id
      domain_name
      domain_description
      polygon
    }
  }
`;

// ---------------------- SERVICES ---------------------
export const createService = /* GraphQL */ `
  mutation createService(
    $description: String
    $service_name: String!
    $service_type: String!
  ) {
    createService(
      input: {
        description: $description
        service_name: $service_name
        service_type: $service_type
      }
    ) {
      description
      service_id
      service_name
      service_state
      service_type
    }
  }
`;

export const createTranslationService = /* GraphQL */ `
  mutation createTranslationService($input: ServiceTranslationInput!) {
    createTranslationService(input: $input) {
      description
      service_name
      service_type
      service_id
      service_state
    }
  }
`;

export const editService = /* GraphQL */ `
  mutation editService($input: EditServiceInput!) {
    editService(input: $input) {
      service_name
      service_id
    }
  }
`;

export const editTranslationService = /* GraphQL */ `
  mutation editTranslationService($input: ServiceTranslationInput!) {
    editTranslationService(input: $input) {
      service_id
      service_name
      description
      service_type
      service_state
      lang
    }
  }
`;

export const deleteService = /* GraphQL */ `
  mutation deleteService(
    $service_id: String!
    $service_name: String!
    $service_type: String!
    $ws_id: String
  ) {
    deleteService(
      input: {
        service_id: $service_id
        service_name: $service_name
        service_type: $service_type
        ws_id: $ws_id
      }
    ) {
      service_id
      service_name
      service_type
    }
  }
`;

// ---------------------- WORKSPACE SERVICE ---------------------
export const createWorkspaceService = /* GraphQL */ `
  mutation createWorkspaceService($input: WorkspaceServiceInput!) {
    createWorkspaceService(input: $input) {
      service_id
    }
  }
`;

export const updateWorkspaceService = /* GraphQL */ `
  mutation updateWorkspaceService($input: WorkspaceServiceInput!) {
    updateWorkspaceService(input: $input) {
      service_id
    }
  }
`;

// ---------------------- URL SERVICE ---------------------
export const createUrlService = /* GraphQL */ `
  mutation createUrlService($input: [UrlServiceInput]!) {
    createUrlService(input: $input) {
      service_id
      node_id
      node_name
      sort_id
      father_id
    }
  }
`;

export const editUrlService = /* GraphQL */ `
  mutation editUrlService($input: [UrlServiceInput]!) {
    editUrlService(input: $input) {
      service_id
    }
  }
`;

export const createUrlServiceTranslation = /* GraphQL */ `
  mutation createUrlServiceTranslation($input: [UrlServiceTranslationInput]!) {
    createUrlServiceTranslation(input: $input) {
      service_id
    }
  }
`;

export const deleteUrlService = /* GraphQL */ `
  mutation deleteUrlService($input: [UrlServiceInput]!) {
    deleteUrlService(input: $input) {
      service_id
    }
  }
`;

// ---------------------- DOCUMENTAL SERVICE ---------------------
export const createDocumentalService = /* GraphQL */ `
  mutation createDocumentalService($input: [DocumentalServiceInput]!) {
    createDocumentalService(input: $input) {
      service_id
      node_id
      node_name
      sort_id
      father_id
    }
  }
`;

export const editDocumentalService = /* GraphQL */ `
  mutation editDocumentalService($input: [DocumentalServiceInput]!) {
    editDocumentalService(input: $input) {
      service_id
    }
  }
`;

export const createDocumentalServiceTranslation = /* GraphQL */ `
  mutation createDocumentalServiceTranslation(
    $input: [DocumentalServiceTranslationInput]!
  ) {
    createDocumentalServiceTranslation(input: $input) {
      service_id
    }
  }
`;

export const deleteDocumentalService = /* GraphQL */ `
  mutation deleteDocumentalService($input: [DocumentalServiceInput]!) {
    deleteDocumentalService(input: $input) {
      service_id
    }
  }
`;

// ---------------------- REPORT HUB SERVICE ---------------------
export const createReportHubService = /* GraphQL */ `
  mutation createReportHubService($input: [ReportHubServiceInput]!) {
    createReportHubService(input: $input) {
      service_id
    }
  }
`;

export const editReportHubService = /* GraphQL */ `
  mutation editReportHubService($input: [ReportHubServiceInput]!) {
    editReportHubService(input: $input) {
      service_id
    }
  }
`;

export const createReportHubServiceTranslation = /* GraphQL */ `
  mutation createReportHubServiceTranslation($input: [ReportHubServiceTranslationInput]!) {
    createReportHubServiceTranslation(input: $input) {
      service_id
    }
  }
`;

export const deleteReportHubService = /* GraphQL */ `
  mutation deleteReportHubService($input: [ReportHubServiceInput]!) {
    deleteReportHubService(input: $input) {
      service_id
    }
  }
`;

// ---------------------- GLOBAL LAYERS ---------------------

export const createGlobalLayer = /* GraphQL */ `
  mutation createGlobalLayer($input: GlobalLayerInput!) {
    createGlobalLayer(input: $input) {
      global_layer_id
      layer_name
      layer_description
      layer_polygon
    }
  }
`;

export const editGlobalLayer = /* GraphQL */ `
  mutation editGlobalLayer($input: GlobalLayerInput!) {
    editGlobalLayer(input: $input) {
      global_layer_id
      layer_name
      layer_description
      layer_polygon
    }
  }
`;

export const deleteGlobalLayer = /* GraphQL */ `
  mutation deleteGlobalLayer($input: DeleteGlobalLayerInput!) {
    deleteGlobalLayer(input: $input) {
      global_layer_id
      layer_name
      layer_description
      layer_polygon
    }
  }
`;

export const deleteCustomerLayer = /* GraphQL */ `
  mutation deleteCustomerLayer($input: DeleteGlobalLayerInput!) {
    deleteCustomerLayer(input: $input) {
      service_id
    }
  }
`;

export const createGlobalLayerTranslaction = /* GraphQL */ `
  mutation createGlobalLayerTranslaction($input: GlobalLayerTranslationInput!) {
    createGlobalLayerTranslaction(input: $input) {
      global_layer_id
      layer_name
      layer_description
      layer_polygon
    }
  }
`;

export const editGlobalLayerTranslaction = /* GraphQL */ `
  mutation editGlobalLayerTranslaction($input: GlobalLayerTranslationInput!) {
    editGlobalLayerTranslaction(input: $input) {
      global_layer_id
      layer_name
      layer_description
      layer_polygon
    }
  }
`;

export const saveDataLayer = /* GraphQL */ `
  mutation saveDataLayer($input: DataLayerInput!) {
    saveDataLayer(input: $input) {
      global_layer_id
      color
      width
      layer_type
    }
  }
`;

export const linkServices = /* GraphQL */ `
  mutation linkServices($input: [LinkServiceInput]!) {
    linkServices(input: $input) {
      service_id
    }
  }
`;

export const unlinkServices = /* GraphQL */ `
  mutation unlinkServices($input: [LinkServiceInput]!) {
    unlinkServices(input: $input) {
      service_id
    }
  }
`;

// ---------------------- TRANSLATIONS ---------------------
export const editTranslationNodes = /* GraphQL */ `
  mutation editTranslationNodes($input: [ListTranslationNodesInput]!) {
    editTranslationNodes(input: $input) {
      node_id
      node_name
      node_description
    }
  }
`;

// ---------------------- POLICY ---------------------
export const createServicesPolicy = /* GraphQL */ `
  mutation createServicesPolicy($input: servicePolicyInput!) {
    createServicesPolicy(input: $input) {
      policy_id
      service_id
    }
  }
`;

export const editServicesPolicy = /* GraphQL */ `
  mutation editServicesPolicy($input: servicePolicyInput!) {
    editServicesPolicy(input: $input) {
      policy_id
      service_id
    }
  }
`;

export const configurationDocumentalServicesPolicy = /* GraphQL */ `
  mutation configurationDocumentalServicesPolicy($input: [documentalServicePolicyInput]!) {
    configurationDocumentalServicesPolicy(input: $input) {
      service_id
    }
  }
`;

export const configurationUrlServicesPolicy = /* GraphQL */ `
  mutation configurationUrlServicesPolicy($input: [urlServicePolicyInput]!) {
    configurationUrlServicesPolicy(input: $input) {
      service_id
    }
  }
`;

export const configurationWorkspaceServicesPolicy = /* GraphQL */ `
  mutation configurationWorkspaceServicesPolicy(
    $input: workspaceServicePolicyInput!
  ) {
    configurationWorkspaceServicesPolicy(input: $input) {
      service_id
    }
  }
`;

export const updateWorkspaceServicesPolicy = /* GraphQL */ `
  mutation updateWorkspaceServicesPolicy(
    $input: workspaceServicePolicyInput!
  ) {
    updateWorkspaceServicesPolicy(input: $input) {
      service_id
    }
  }
`;

export const linkUserPolicies = /* GraphQL */ `
  mutation linkUserPolicies($input: [linkAppUserPolicy]!) {
    linkUserPolicies(input: $input) {
      service_id
    }
  }
`;

export const unlinkUserPolicy = /* GraphQL */ `
  mutation unlinkUserPolicy($input: [linkAppUserPolicy]!) {
    unlinkUserPolicy(input: $input) {
      service_id
    }
  }
`;

export const deleteServicePolicy = /* GraphQL */ `
  mutation deleteServicePolicy($input: PolicyServiceInput!) {
    deleteServicePolicy(input: $input) {
      service_id
      policy_id
    }
  }
`;

// ---------------------- TICKETING SERVICE ---------------------
export const createTicketingService = /* GraphQL */ `
  mutation createTicketingService($input: TicketingServiceInput!) {
    createTicketingService(input: $input) {
      service_id
    }
  }
`;

export const editTicketingService = /* GraphQL */ `
  mutation editTicketingService($input: TicketingServiceInput!) {
    editTicketingService(input: $input) {
      service_id
    }
  }
`;

export const deleteTicketingService = /* GraphQL */ `
  mutation deleteTicketingService($input: DeleteTicketingServiceInput!) {
    deleteTicketingService(input: $input) {
      service_id
    }
  }
`;

export const createEditTicketingServiceTranslation = /* GraphQL */ `
  mutation createEditTicketingServiceTranslation($input: TicketingServiceTranslationInput!) {
    createEditTicketingServiceTranslation(input: $input) {
      service_id
    }
  }
`;

export const createProfilingTicketingService = /* GraphQL */ `
  mutation createProfilingTicketingService($input: ProfilingTicketingServiceInput!) {
    createProfilingTicketingService(input: $input) {
      service_id
    }
  }
`;

export const editProfilingTicketingService = /* GraphQL */ `
  mutation editProfilingTicketingService($input: ProfilingTicketingServiceInput!) {
    editProfilingTicketingService(input: $input) {
      service_id
    }
  }
`;

export const deleteProfilingTicketingService = /* GraphQL */ `
  mutation deleteProfilingTicketingService($input: ProfilingTicketingServiceInput!) {
    deleteProfilingTicketingService(input: $input) {
      service_id
    }
  }
`;

export const linkAppUserToProfilingNode = /* GraphQL */ `
  mutation linkAppUserToProfilingNode($input: [LinkAppUserToProfilingNodeInput]!) {
    linkAppUserToProfilingNode(input: $input) {
      service_id
    }
  }
`;

export const unlinkAppUserToProfilingNode = /* GraphQL */ `
  mutation unlinkAppUserToProfilingNode($input: LinkAppUserToProfilingNodeInput!) {
    unlinkAppUserToProfilingNode(input: $input) {
      service_id
    }
  }
`;

export const unlinkUserServices = /* GraphQL */ `
  mutation unlinkUserServices($input: linkAppUserPolicyResult!) {
    unlinkUserServices(input: $input) {
      service_id
    }
  }
`;

export const createTicketingRequest = /* GraphQL */ `
  mutation createTicketingRequest($input: TicketingRequestInput!) {
    createTicketingRequest(input: $input) {
      service_id
    }
  }
`;

export const editTicketingRequestLimit = /* GraphQL */ `
  mutation editTicketingRequestLimit($input: TicketingRequestLimitInput!) {
    editTicketingRequestLimit(input: $input) {
      service_id
    }
  }
`;

export const linkLocationToTicketingRequest = /* GraphQL */ `
  mutation linkLocationToTicketingRequest($input: [LinkLocationToTicketingRequestInput]!) {
    linkLocationToTicketingRequest(input: $input) {
      service_id
    }
  }
`;

export const deleteTicketingRequest = /* GraphQL */ `
  mutation deleteTicketingRequest($input: DeleteTicketingRequestInput!) {
    deleteTicketingRequest(input: $input) {
      service_id
    }
  }
`;

export const linkDocumentS3ToTicketingRequest = /* GraphQL */ `
  mutation linkDocumentS3ToTicketingRequest($input: [LinkDocumentS3ToTicketingRequestInput]!) {
    linkDocumentS3ToTicketingRequest(input: $input) {
      service_id
    }
  }
`;

export const deleteDocumentS3 = /* GraphQL */ `
  mutation deleteDocumentS3($document_s3_id: String!) {
    deleteDocumentS3(document_s3_id: $document_s3_id) {
      service_id
    }
  }
`;

export const editTicketingRequestState = /* GraphQL */ `
  mutation editTicketingRequestState($input: TicketingRequestStateInput!) {
    editTicketingRequestState(input: $input) {
      service_id
    }
  }
`;

export const deleteLinkedLocationToTicketingRequest = /* GraphQL */ `
  mutation deleteLinkedLocationToTicketingRequest($input: [LinkLocationToTicketingRequestInput]!) {
    deleteLinkedLocationToTicketingRequest(input: $input) {
      service_id
    }
  }
`;

export const editTicketingRequest = /* GraphQL */ `
  mutation editTicketingRequest($input: TicketingRequestInput!) {
    editTicketingRequest(input: $input) {
      service_id
    }
  }
`;

export const editEvasionLimitSlots = /* GraphQL */ `
  mutation editEvasionLimitSlots($input: EvasionLimitSlotsInput!) {
    editEvasionLimitSlots(input: $input) {
      service_id
    }
  }
`;

export const deleteDocumentalUrlNode = /* GraphQL */ `
  mutation deleteDocumentalUrlNode($input: DocumentalUrlNodeInput!) {
    deleteDocumentalUrlNode(input: $input) {
      service_id
    }
  }
`;
